.header_1 .header_avatar .avatar_popup {
  min-width: 220px;
}

.header_1 .header_avatar:hover .avatar_popup {
  opacity: 1;
  visibility: visible;
}

.wallet_address {
  display: flex !important;
}

.wallet_address span {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mt-30 {
  margin-top: 30px;
}

.logo-img {
  width: 200px !important;
  height: 40px !important;
}

.amount {
  display: none !important;
}

@media only screen and (max-width: 1280px) {
  .amount {
    display: block !important;
  }
}
