.mb-50 {
  padding-bottom: 50px;
}

.owner-avatar {
  height: 100%;
}

.detail-logo {
  width: 100%;
  height: 500px;
}

.tags {
  display: flex;
  justify-content: center;
}

.tag {
  width: 70%;
}

.warning {
  border-color: brown !important;
}

.warning:hover {
  background-color: brown !important;
}

.warning:hover span {
  color: white !important;
}
