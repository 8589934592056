.imges1 {
  width: 100% !important;
  /* min-width: 409px !important; */
  height: 272px !important;
}

.cards {
  width: 100% !important;
  /* min-width: 409px !important; */
  height: 64px !important;
}

.collect {
  margin-bottom: 30px;
}

.owner-logo {
  width: 64px !important;
  height: 64px !important;
}
