.category {
  padding-top: 0px !important;
  display: block;
}

.category-selector {
  width: 100% !important;
}

/* .selector ul {
  display: none !important;
}

.selector:hover ul {
  display: block !important;
} */

.relative {
  position: relative;
}
