.mt-50 {
  margin-top: 50px;
}

.author-height {
  min-height: 280px;
}

.tabs {
  width: 100%;
}

.logo-imges {
  width: 300px;
  height: 270px;
}

.btn-list {
  display: flex;
  flex-direction: column;
}
