.logo-imges {
  width: 300px !important;
  height: 270px !important;
}

.loader {
  display: none;
  width: 102%;
  height: 101%;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 20;
  left: -1px;
  top: 0;
  background-color: #5242fc9d;
  border-radius: 30px;
}

.inline {
  display: inline-block !important;
}
